







































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  inheritAttrs: false,
  name: 'BaseAutoComplete',
  components: {
    BaseControlWrapper
  }
})
export default class BaseAutoComplete extends BaseFormControl {
  @Prop({ default: () => [] }) items!: Array<string>;

  searchString: string | null = null;

  @Watch('searchString')
  onSearch(newValue: string): void {
    this.$emit('searchUpdated', newValue);
  }

  created(): void {
    super.created();
  }
}
