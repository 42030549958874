var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-control-container"},[_c('base-control-wrapper',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(ref){
var label = ref.label;
return [_vm._t("header",null,{"label":label})]}},{key:"form-control",fn:function(ref){return [_c('v-autocomplete',_vm._b({ref:_vm.id,staticClass:"base-control-container-auto-complete",attrs:{"disabled":_vm.disabled,"items":_vm.items,"loading":_vm.loading,"multiple":_vm.$attrs.multiple,"readonly":_vm.disabled,"search-input":_vm.searchString,"value":_vm.$attrs.value,"appendIcon":"keyboard_arrow_down","dense":"","flat":"","height":"50","hide-details":"","solo":""},on:{"update:searchInput":function($event){_vm.searchString=$event},"update:search-input":function($event){_vm.searchString=$event},"change":function (e) { return _vm.$emit('input', e); }},scopedSlots:_vm._u([(_vm.$scopedSlots.item)?{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("item",null,{"attrs":attrs,"item":item,"on":on,"parent":parent})]}}:null,(_vm.$scopedSlots.selection)?{key:"selection",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var index = ref.index;
var select = ref.select;
var selected = ref.selected;
var disabled = ref.disabled;
return [_vm._t("selection",null,{"disabled":disabled,"index":index,"item":item,"parent":parent,"select":select,"selected":selected})]}}:null,{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.noDataAvailable'))+" ")]},proxy:true}],null,true)},'v-autocomplete',_vm.$attrs,false))]}},{key:"errors",fn:function(ref){
var errors = ref.errors;
return [_vm._t("errors",null,{"errors":errors})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'base-control-wrapper',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }