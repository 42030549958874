








































































































import Vue from 'vue';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseModal from '@improve/common-components/src/components/widgets/BaseModal.vue';
import BaseTextArea from '@improve/common-components/src/components/core/BaseTextArea.vue';
import BaseTextInput from '@improve/common-components/src/components/core/BaseTextInput.vue';
import Organization from '@improve/common-utils/src/model/Organization';
import User from '@improve/common-utils/src/model/User';
import InviteUsersParams from '@improve/common-utils/src/types/InviteUsersParams';

@Component({
  name: 'InviteUsersModal',
  components: {
    BaseModal,
    BaseButton,
    BaseTextInput,
    BaseTextArea
  }
})
export default class InviteUsersModal extends Vue {
  @Prop({ default: false }) readonly currentUsers!: Array<User>;

  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: false }) readonly inProgress!: boolean;

  @Getter currentOrganization!: Organization;

  @Action inviteUsersByEmail!: ActionMethod;

  @Action setAlertMessage!: ActionMethod;

  emailInput: string | null = null;

  showSelectedEmails = false;

  selectedEmails: Array<string> = [];

  invitationMsg = this.$t('form.invitationMessage').toString();

  get numberOfEmailsSelected(): string {
    return this.selectedEmails.length
      ? `${this.selectedEmails.length} ${this.$t('page.userSearchModal.selected')}`
      : '';
  }

  get emailEditor(): any {
    return this.$refs.emailEditor;
  }

  get invitationMsgEditor(): any {
    return this.$refs.invitationMsgEditor;
  }

  get emailInputs(): Array<string> {
    return this.emailInput
      ? this.emailInput
        .replaceAll(',', ' ')
        .replaceAll(/ +/g, ' ')
        .split(' ')
      : [];
  }

  async addEmail(): Promise<void> {
    if (!this.emailInputs.length) {
      this.setErrors('emailAddressRequired');
      return;
    }

    const singleInput = this.emailInputs.length === 1;
    const validEmails = this.emailInputs
      .map((email: string) => email.toLowerCase())
      .filter((email) => this.validateEmailInput(email, !singleInput));

    if (validEmails.length) {
      this.selectedEmails.push(...new Set(validEmails) as Set<string>);
      this.resetEmailInput();
    }
  }

  removeEmail(email: string): void {
    this.selectedEmails = this.selectedEmails.filter((e) => e !== email);
  }

  async inviteConfirm(): Promise<void> {
    if (!(await this.validateInvMsg())) {
      return;
    }
    if (!this.selectedEmails.length) {
      this.setErrors('emailAddressRequired');
      return;
    }
    const params = new InviteUsersParams(this.selectedEmails, this.invitationMsg, 'en');
    await this.inviteUsersByEmail(params);
    await this.setAlertMessage({
      message: this.$t('form.invitationsSent').toString(),
      show: true
    });
    this.$emit('completed', this.selectedEmails);
  }

  validateEmailInput(singleEmail: string, silent = false): boolean {
    if (!this.validateEmail(singleEmail)) {
      if (!silent) this.setErrors('invalidEmail');
      return false;
    }
    if (!this.validateEmailDomain(singleEmail)) {
      if (!silent) this.setErrors('emailWrongContext');
      return false;
    }
    if (this.checkIfEmailAlreadySelected(singleEmail)) {
      if (!silent) this.setErrors('emailAlreadySelected');
      return false;
    }
    if (this.checkIfEmailAlreadyExists(singleEmail)) {
      if (!silent) this.setErrors('emailAlreadyExists');
      return false;
    }
    return true;
  }

  async validateInvMsg(): Promise<boolean> {
    return this.invitationMsgEditor.validate();
  }

  validateEmail(email: string): boolean {
    const reg = /\S+@\S+\.\S+/;
    return reg.test(email);
  }

  validateEmailDomain(email: string): boolean {
    const { domains } = this.currentOrganization;
    const emailDomain = email.slice(email.indexOf('@') + 1);
    return domains?.includes(emailDomain) || false;
  }

  checkIfEmailAlreadySelected(email: string): boolean {
    return this.selectedEmails.some((e) => e === email);
  }

  checkIfEmailAlreadyExists(email: string): boolean {
    return this.currentUsers.some((e) => e.email === email);
  }

  resetEmailInput(): void {
    setTimeout(() => {
      this.emailInput = null;
      this.emailEditor.reset();
    }, 50);
  }

  setErrors(errorType: string): void {
    const errors: any = {};
    const errorMessage = this.$t(`error.${errorType}`).toString();
    errors[this.$t('form.email').toString()] = errorMessage;
    this.emailEditor.setErrors(errors);
  }
}
