export default class InviteUsersParams {
  constructor(emails: Array<string>, message: string, lang: string) {
    this.emails = emails;
    this.message = message;
    this.language = lang;
    this.verify = false;
  }

  emails: Array<string>;

  language: string;

  message: string;

  verify: boolean;
}
