




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseTabOption from '@improve/common-utils/src/types/TabOption';

@Component({
  name: 'BaseTabs'
})
export default class BaseTabs extends Vue {
  @Prop({ default: null }) readonly selectedValue!: string;

  @Prop({ default: null }) readonly options!: Array<BaseTabOption>;
}
