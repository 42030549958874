

















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action } from 'vuex-class';
import { debounce } from 'lodash';
import BaseUserCard from '@improve/common-components/src/components/widgets/BaseUserCard.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseAutoComplete from '@improve/common-components/src/components/core/BaseAutoComplete.vue';
import BaseTextInput from '@improve/common-components/src/components/core/BaseTextInput.vue';
import BaseTabs from '@improve/common-components/src/components/widgets/BaseTabs.vue';
import User from '@improve/common-utils/src/model/User';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import RoleType from '@improve/common-utils/src/types/RoleType';
import BaseTabOption from '@improve/common-utils/src/types/TabOption';
import UserSearchParams from '@improve/common-utils/src/types/UserSearchParams';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';
import InviteUsersModal from '../components/users/InviteUsersModal.vue';

@Component({
  name: 'Users',
  components: {
    TabContent,
    BaseUserCard,
    BaseAutoComplete,
    BaseTabs,
    BaseTextInput,
    BaseButton,
    InviteUsersModal
  }
})
export default class Users extends Vue {
  @Action fetchUsers!: ActionMethod;

  @Action addUserRoles!: ActionMethod;

  @Action removeUserRoles!: ActionMethod;

  @Action deleteUser!: ActionMethod;

  users: Array<User> = [];

  search = '';

  searchInProgress = false;

  options: Array<BaseTabOption> = [];

  showInviteUsersModal = false;

  readonly allUsersTabName = 'AllUsers';

  readonly adminsTabName = 'Admins';

  debounceSearchInput = debounce((event: string) => {
    this.onSearch(event);
  }, 300);

  get currentTab(): string {
    return this.$route.name || '';
  }

  get query(): string {
    return this.$route.query.q ? this.$route.query.q!.toString() : '';
  }

  created(): void {
    this.initTabs();
    this.onSearch(this.query, false);
    this.search = this.query;
  }

  async onSearch(search: string, updateUrl = true): Promise<void> {
    if (search.length < 3 && search !== '') {
      return;
    }
    this.searchInProgress = true;
    this.users = await this.fetchUsers({
      params: this.getSearchParamsByTab(this.currentTab, search),
      fetchRoles: true
    });
    if (updateUrl) {
      this.$router.replace({
        query: search
          ? { q: search } : {}
      });
    }
    this.searchInProgress = false;
  }

  initTabs(): void {
    this.options = [
      {
        title: this.$t('menu.allUsers').toString(),
        value: this.allUsersTabName
      },
      {
        title: this.$t('menu.admins').toString(),
        value: this.adminsTabName
      }
    ];
  }

  inviteUsers(): void {
    this.showInviteUsersModal = true;
  }

  getSearchParamsByTab(tabName: string, searchTerm: string): UserSearchParams {
    const searchParams = new UserSearchParams();
    switch (tabName) {
      case this.allUsersTabName: {
        searchParams.name = searchTerm;
        break;
      }
      case this.adminsTabName: {
        searchParams.name = searchTerm;
        searchParams.roles = [RoleType.ADMIN];
        break;
      }
      default: {
        break;
      }
    }
    return searchParams;
  }

  getCardMenuOptions(user: User): MenuOption[] {
    const options = [];
    if (!user.isAdmin()) {
      options.push({
        title: this.$t('menu.makeAdmin').toString(),
        value: 'makeAdmin'
      });
    } else {
      options.push({
        title: this.$t('menu.removeAdmin').toString(),
        value: 'removeAdmin'
      });
    }
    options.push({
      title: this.$t('menu.delete').toString(),
      value: 'delete'
    });
    return options;
  }

  async onCardMenuOptionSelect(payload: { user: User; option: MenuOption }): Promise<void> {
    switch (payload.option.value) {
      case 'makeAdmin': {
        payload.user.roles.push(RoleType.USER, RoleType.ADMIN, RoleType.ASSESSMENT);
        this.users = this.users.map((u) => (u.id === payload.user.id ? payload.user : u));
        await this.addUserRoles({
          user: payload.user,
          roles: [RoleType.USER, RoleType.ADMIN, RoleType.ASSESSMENT]
        });
        break;
      }
      case 'removeAdmin': {
        payload.user.roles.splice(payload.user.roles.indexOf(RoleType.ADMIN), 1);
        this.users = this.users.map((u) => (u.id === payload.user.id ? payload.user : u));
        await this.removeUserRoles({
          user: payload.user,
          roles: [RoleType.ADMIN, RoleType.ASSESSMENT]
        });
        this.users = this.currentTab === this.adminsTabName
          ? this.users.filter((user: User) => user !== payload.user)
          : this.users;
        break;
      }
      case 'delete': {
        await this.deleteUser(payload.user.id);
        this.users = this.users.filter((user: User) => user !== payload.user);
        break;
      }
      default: {
        // because eslint doesnt like empty block
        break;
      }
    }
  }

  goToTab(tabValue: string): void {
    const params = this.query ? { name: tabValue, query: { q: this.query } } : { name: tabValue };
    this.$router.push(params);
    setTimeout(this.onSearch, 100, this.query, false);
  }
}
